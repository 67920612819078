<template>
  <div class="ui form">
    <m-btn
      basic
      fluid
      color="blue"
      @click="
        title.text = table.title
        title.valid = true
        title.show = true
      "
      >테이블 이름 변경</m-btn
    >
    <m-dialog v-model="title.show">
      <div class="ui form">
        <m-field-text v-model="title.text" label="테이블 이름" :valid="title.valid"></m-field-text>
      </div>
      <template #actions>
        <m-btn
          color="secondary"
          @click="
            title.show = false
            title.text = null
            title.valid = true
          "
          >취소</m-btn
        >
        <m-btn color="primary" @click="saveTitle"><i class="ui check icon"></i>확인</m-btn>
      </template>
    </m-dialog>
    <div class="ui divider"></div>
    <m-field-check-box label="숨기기" toggle :value="hidden" @update="updateHidden"></m-field-check-box>
    <div class="ui divider"></div>
    <m-field-check-box label="입력 요약 보기" toggle :value="summary" @update="updateSummary"></m-field-check-box>
    <m-field-dropdown label="" :items="summaryModeItems" :value="summaryMode" :valid="true" @update="updateSummaryMode" :disabled="!summary"></m-field-dropdown>
    <m-field-dropdown
      icon
      label=""
      :items="summaryDailyColumnItems"
      :value="summaryColumnId"
      :valid="true"
      @update="updateSummaryColumnId"
      v-show="summaryMode === 'DAILY_COLUMN'"
    ></m-field-dropdown>
    <m-field-dropdown
      icon
      label=""
      :items="summaryMonthlyColumnItems"
      :value="summaryColumnId"
      :valid="true"
      @update="updateSummaryColumnId"
      v-show="summaryMode === 'MONTHLY_COLUMN'"
    ></m-field-dropdown>
    <div class="ui divider"></div>
    <m-btn basic fluid color="red" @click="confirmRemove">테이블 삭제</m-btn>
  </div>
</template>

<script>
import TableService from "@/service/TableService"
import MBtn from "@/components/common/MBtn"
import MFieldCheckBox from "@/components/common/MoonTypes/MFieldCheckBox"
import { ResourceType, MetaProperties } from "@/define/ResourceTypes"
import MFieldDropdown from "@/components/common/MFieldDropdown"
import lodash from "lodash"
import { findType } from "@/define/ColumnTypes"
import { $confirm } from "@/utils/SemanticUtil"
import { Page } from "@/define/Page"
import MDialog from "@/components/common/MDialog"
import MFieldText from "@/components/common/MFieldText"
export default {
  name: "TableOptions",
  components: { MFieldText, MDialog, MFieldDropdown, MFieldCheckBox, MBtn },
  props: {
    table: { type: Object, required: true },
  },
  data() {
    return {
      title: { show: false, text: null, valid: true },
      showRemoveDialog: false,
      summaryModeItems: [
        { name: "일간 입력 현황", value: "DAILY_INPUT", active: true },
        { name: "일간 작업 현황", value: "DAILY_COLUMN", active: true },
        { name: "월간 체크 현황", value: "MONTHLY_COLUMN", active: true },
      ],
    }
  },
  computed: {
    hidden() {
      return this.table.meta?.hidden ?? false
    },
    summary() {
      return this.table.meta?.summary ?? false
    },
    summaryMode() {
      return this.table.meta?.summaryMode ?? "DAILY_INPUT"
    },
    summaryColumnId() {
      return this.table.meta?.summaryColumnId
    },
    summaryDailyColumnItems() {
      const columns = lodash.flatten(lodash.map(this.table.columnGroups, "columns"))
      const filteredColumns = lodash.filter(columns, { type: "LIST_TASK" })
      return lodash.map(filteredColumns, (column) => ({
        value: column.id,
        name: column.title,
        active: findType(column.type).active,
        icon: findType(column.type).icon,
      }))
    },
    summaryMonthlyColumnItems() {
      const columns = lodash.flatten(lodash.map(this.table.columnGroups, "columns"))
      const filteredColumns = lodash.filter(columns, { type: "CHECKBOX" })
      return lodash.map(filteredColumns, (column) => ({
        value: column.id,
        name: column.title,
        active: findType(column.type).active,
        icon: findType(column.type).icon,
      }))
    },
  },
  methods: {
    saveTitle() {
      if (this.title.text) {
        TableService.changeTableTitle({ ...this.table, title: this.title.text }).then(() => {
          this.$emit("update")
          this.title.show = false
        })
      } else {
        this.title.valid = false
      }
    },
    updateHidden(value) {
      console.log(value)
      TableService.updateMeta(ResourceType.Table.key, this.table.id, MetaProperties.hidden.key, value).then(() => {
        this.$emit("update")
      })
    },
    updateSummary(value) {
      console.log(value)
      TableService.updateMeta(ResourceType.Table.key, this.table.id, MetaProperties.summary.key, value).then(() => {
        this.$emit("update")
      })
    },
    updateSummaryMode(value) {
      console.log(value)
      TableService.updateMeta(ResourceType.Table.key, this.table.id, MetaProperties.summaryMode.key, value).then(() => {
        this.$emit("update")
      })
    },
    updateSummaryColumnId(value) {
      console.log(value)
      TableService.updateMeta(ResourceType.Table.key, this.table.id, MetaProperties.summaryColumnId.key, value).then(() => {
        this.$emit("update")
      })
    },
    confirmRemove: function () {
      const { id, title } = this.table
      $confirm(`<span class="ui red large text">${title}</span> 테이블을 삭제합니다`, "삭제 확인", () => {
        TableService.removeTable(id).then(() => {
          this.$router.push({ name: Page.MyTables.name })
        })
      })
    },
  },
}
</script>

<style scoped></style>
