import lodash from "lodash"

export const Colors = {
  primary: { class: "primary", name: "Primary", use: false },
  secondary: { class: "secondary", name: "Secondary", use: false },
  red: { class: "red", name: "Red", use: true },
  orange: { class: "orange", name: "Orange", use: true },
  yellow: { class: "yellow", name: "Yellow", use: true },
  olive: { class: "olive", name: "Olive", use: true },
  green: { class: "green", name: "Green", use: true },
  teal: { class: "teal", name: "Teal", use: true },
  blue: { class: "blue", name: "Blue", use: true },
  violet: { class: "violet", name: "Violet", use: true },
  purple: { class: "purple", name: "Purple", use: true },
  pink: { class: "pink", name: "Pink", use: true },
  brown: { class: "brown", name: "Brown", use: true },
  grey: { class: "grey", name: "Grey", use: true },
  black: { class: "black", name: "Black", use: true },
}
export const MenuColors = function () {
  return lodash.chain(Colors).values().filter({ use: true }).value()
}
