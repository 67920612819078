<template>
  <div>
    <page-menu :links="links">
      <div class="ui header">테이블 설정</div>
    </page-menu>

    <m-tabs :items="tabItems" v-model="tab" secondary></m-tabs>

    <m-tab-panel :itemValue="1" :value="tab" basic>
      <column-groups :column-groups="table.columnGroups" @update="fetchData"></column-groups>
    </m-tab-panel>
    <m-tab-panel :itemValue="2" :value="tab" basic>
      <table-options :table="table" @update="fetchData"></table-options>
    </m-tab-panel>
    <m-tab-panel :itemValue="3" :value="tab" basic>
      <table-memo :table="table" @update="fetchData"></table-memo>
    </m-tab-panel>
  </div>
</template>

<script>
import PageMixin from "@/mixins/PageMixin"
import TableService from "@/service/TableService"
import { types } from "@/define/ColumnTypes"
import MTabs from "@/components/common/MTabs"
import MTabPanel from "@/components/common/MTabPanel"
import TableMemo from "@/pages/TableEditor/TableMemo"
import TableOptions from "@/pages/TableEditor/TableOptions"
import PageMenu from "@/components/common/PageMenu"
import ColumnGroups from "@/pages/TableEditor/ColumnGroups"

export default {
  name: "TableEditor",
  components: {
    ColumnGroups,
    PageMenu,
    TableOptions,
    TableMemo,

    MTabPanel,
    MTabs,
  },
  mixins: [PageMixin],
  data() {
    return {
      links: [
        { title: "테이블 보기", to: { name: "TableListView", params: { id: this.$route.params.id } }, icon: "table" },
        { title: "달력으로 보기", to: { name: "TableMonthlyView", params: { id: this.$route.params.id } }, icon: "calendar" },
        { title: "리포트 보기", to: { name: "TableReportView", params: { id: this.$route.params.id } }, icon: "area chart" },
        { title: "테이블 리스트", to: { name: "MyTables" }, icon: "th list" },
      ],
      tabItems: [
        { value: 1, text: "컬럼" },
        { value: 2, text: "옵션" },
        { value: 3, text: "메모" },
      ],
      tab: 1,
      fetched: false,
      columnTypeItems: types,
      table: {
        id: 0,
        title: "",
        description: "",
        columnGroups: [],
        orders: 0,
        meta: {},
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    loading() {
      return this.$store.state.page.loading
    },
  },
  methods: {
    fetchData() {
      // console.debug("fetch tables")
      this.fetched = false
      const id = this.$route.params.id
      return TableService.getMyTableWithSchema(id).then(({ status, data }) => {
        if (status === 200) {
          // console.debug(data)
          this.$store.commit("page/changeTitle", data.data.title)
          this.table = data.data
          this.fetched = true
        }
      })
    },
  },
}
</script>

<style scoped></style>
