export const ResourceType = {
  Table: { key: "TABLE" },
  ColumnGroup: { key: "COLUMN_GROUP" },
  Column: { key: "COLUMN" },
  Data: { key: "DATA" },
  Item: { key: "ITEM" },
  DataItem: { key: "DATA_ITEM" },
}

export const MetaProperties = {
  hidden: { key: "hidden", type: Boolean },
  color: { key: "color", type: String },
  summary: { key: "summary", type: Boolean },
  summaryMode: { key: "summaryMode", type: String }, // ["DAILY_INPUT", "DAILY_COLUMN", "MONTHLY_COLUMN"]
  summaryColumnId: { key: "summaryColumnId", type: Number },
}
