<template>
  <div class="ui menu" :class="{ secondary, pointing, text, tabular, top, attached }">
    <div class="item" :class="{ active: value === item.value }" v-for="item in items" :key="item.value" @click="clickTab(item.value)">
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MTabs",
  props: {
    value: {
      type: [Number, String, Object],
    },
    items: {
      type: Array,
      default: () => [],
    },
    secondary: Boolean,
    pointing: Boolean,
    text: Boolean,
    tabular: Boolean,
    top: Boolean,
    attached: Boolean,
  },
  data() {
    return {}
  },
  methods: {
    clickTab(value) {
      this.$emit("input", value)
    },
  },
}
</script>

<style scoped></style>
