<template>
  <div class="item">
    <div class="right floated content"><i class="ui delete grey link icon" @click="$emit('remove', item)"></i></div>
    <div class="right floated content">
      <column-item-menu :selectedColor="color" @color="changeColor"></column-item-menu>
    </div>
    <div class="content">
      <i class="ui grip vertical grey icon handle"></i>
      <div class="ui empty circular label" :class="[color]"></div>
      {{ title }}
    </div>
    <!--    <div class="clearfix"></div>-->
  </div>
</template>

<script>
import ColumnItemMenu from "@/pages/TableEditor/ColumnItemMenu"
export default {
  name: "ColumnItem",
  components: { ColumnItemMenu },
  props: {
    item: { type: Object },
  },
  data() {
    return {
      color: null,
      title: null,
    }
  },
  watch: {
    item: {
      deep: true,
      handler(value) {
        this.color = value.color
        this.title = value.title
      },
    },
  },
  methods: {
    changeColor(color) {
      this.color = color
      this.$emit("color", this.item, color)
    },
  },
  created() {
    this.color = this.item.color
    this.title = this.item.title
  },
}
</script>

<style scoped></style>
