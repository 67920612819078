<template>
  <div>
    <div class="ui top attached large header group-title">
      <div class="ui buttons toolbar">
        <div class="ui icon tertiary button">
          <i class="ui move grey icon"></i>
        </div>
        <div class="ui icon tertiary button" @click="removeColumnGroup">
          <i class="ui trash grey icon"></i>
        </div>
      </div>
      <span class="link" @click="openModifyColumnGroup(columnGroup)">{{ columnGroup.title }}</span>
    </div>
    <div class="ui bottom attached segment">
      <m-selection-list>
        <m-selection-item v-for="column in columnGroup.columns" :key="column.id" :icon="findType(column.type).icon" @click="openModifyColumn(column)">
          {{ column.title }}
        </m-selection-item>
        <m-selection-item icon="plus" @click="openNewColumn"><span class="ui grey text">새로운 컬럼을 추가합니다</span></m-selection-item>
      </m-selection-list>
    </div>
    <m-dialog v-model="modifyColumnGroup.showDialog" headerText="컬럼그룹 수정">
      <div class="ui form">
        <m-field-text label="컬럼그룹 이름" v-model="modifyColumnGroup.title.value" :valid="modifyColumnGroup.title.valid"></m-field-text>
      </div>
      <template #actions>
        <m-btn deny @click="modifyColumnGroup.showDialog = false">취소</m-btn>
        <m-btn approve @click="updateColumnGroup"><i class="check icon"></i>확인</m-btn>
      </template>
    </m-dialog>
    <m-dialog v-model="newColumn.showDialog" headerText="새로운 컬럼">
      <div class="ui form">
        <m-field-text label="컬럼 이름" v-model="newColumn.title.value" :valid="newColumn.title.valid"></m-field-text>
        <m-field-dropdown label="컬럼 타입" v-model="newColumn.type.value" :valid="newColumn.type.valid" :items="newColumn.type.items" icon></m-field-dropdown>
      </div>
      <template #actions>
        <m-btn deny @click="newColumn.showDialog = false">취소</m-btn>
        <m-btn approve @click="saveNewColumn"><i class="check icon"></i>확인</m-btn>
      </template>
    </m-dialog>
    <m-dialog v-model="modifyColumn.showDialog" headerText="컬럼 수정">
      <div class="ui form">
        <m-field-text label="컬럼 이름" v-model="modifyColumn.title.value" :valid="modifyColumn.title.valid" @update="updateColumn"></m-field-text>
        <m-field-dropdown
          label="컬럼 타입"
          v-model="modifyColumn.type.value"
          :valid="modifyColumn.type.valid"
          :items="modifyColumn.type.items"
          icon
          @update="updateColumn"
        ></m-field-dropdown>
        <div v-if="modifyColumn.type.value === 'SELECT_SINGLE' || modifyColumn.type.value === 'SELECT_MULTI'">
          <div class="ui sub header">아이템 관리</div>
          <div>
            <div class="ui fluid action input">
              <m-text-input placeholder="아이템 추가" v-model="addItemTitle" @update="addItem"></m-text-input>
              <!--              <input type="text" placeholder="아이템 추가" v-model="addItemTitle" @keydown.enter="addItem" />-->
              <div class="ui primary button" :class="{ disabled: addItemDisabled }" @click="addItem">추가</div>
            </div>
            <div>
              <draggable class="ui middle aligned selection list" ghost-class="ghost" handle=".handle" v-model="modifyColumn.items" animation="100">
                <column-item
                  v-for="item in modifyColumn.items"
                  :key="item.id"
                  :item="item"
                  :title="item.title"
                  :color="item.color"
                  @remove="openRemoveItem"
                  @color="changeItemColor"
                ></column-item>
              </draggable>
            </div>
          </div>
        </div>
        <div class="ui divider"></div>
        <m-btn fluid basic color="red" @click="removeColumnConfirm = true">컬럼 삭제</m-btn>
      </div>
      <template #actions>
        <m-btn deny @click="modifyColumn.showDialog = false">닫기</m-btn>
        <!--        <m-btn deny @click="modifyColumn.showDialog = false">취소</m-btn>-->
        <!--        <m-btn approve @click="updateColumn"><i class="check icon"></i>확인</m-btn>-->
      </template>
    </m-dialog>
    <m-dialog v-model="removeColumnConfirm" headerText="컬럼 삭제">
      <span class="ui red large text">{{ this.modifyColumn.title.value }}</span> 컬럼을 삭제합니다.<br />
      저장된 데이터도 모두 삭제됩니다.
      <template #actions>
        <m-btn
          deny
          @click="
            removeColumnConfirm = false
            modifyColumn.showDialog = true
          "
          >취소</m-btn
        >
        <m-btn approve @click="removeColumn"><i class="check icon"></i>확인</m-btn>
      </template>
    </m-dialog>
    <m-dialog v-model="removeItemTarget.confirm" headerText="아이템 삭제">
      <span class="ui red large text">{{ this.removeItemTarget.title }}</span> 아이템을 삭제합니다.<br />
      연결된 데이터도 모두 삭제됩니다.
      <template #actions>
        <m-btn
          deny
          @click="
            removeItemTarget.confirm = false
            modifyColumn.showDialog = true
          "
          >취소</m-btn
        >
        <m-btn approve @click="removeItem"><i class="check icon"></i>확인</m-btn>
      </template>
    </m-dialog>
  </div>
</template>

<script>
import { types } from "@/define/ColumnTypes"
import TableService from "@/service/TableService"
import lodash from "lodash"
import MSelectionList from "@/components/common/MSelectionList"
import MSelectionItem from "@/components/common/MSelectionItem"
import { $confirm } from "@/utils/SemanticUtil"
import MDialog from "@/components/common/MDialog"
import MFieldText from "@/components/common/MFieldText"
import MFieldDropdown from "@/components/common/MFieldDropdown"
import MBtn from "@/components/common/MBtn"
import draggable from "vuedraggable"
import MTextInput from "@/components/common/MTextInput"
import ColumnItem from "@/pages/TableEditor/ColumnItem"
export default {
  name: "ColumnGroup",
  components: {
    ColumnItem,
    MTextInput,
    draggable,
    MBtn,
    MFieldDropdown,
    MSelectionItem,
    MSelectionList,
    MDialog,
    MFieldText,
  },
  props: {
    columnGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modifyColumnGroup: {
        showDialog: false,
        title: { value: "", valid: true },
      },
      newColumn: {
        showDialog: false,
        title: { value: "", valid: true },
        type: {
          value: types[0].value,
          items: types,
          valid: true,
        },
      },
      modifyColumn: {
        showDialog: false,
        id: null,
        title: { value: "", valid: true },
        items: [],
        type: {
          value: types[0].value,
          items: types,
          valid: true,
        },
      },
      addItemTitle: null,
      removeColumnConfirm: false,
      removeItemTarget: { confirm: false, id: null, title: null },
      modifyItemTarget: { showDialog: false, id: null, title: null, color: null },
    }
  },
  computed: {
    rowspan() {
      return this.columnGroup.columns.length + 1
    },
    addItemDisabled() {
      return !this.addItemTitle
    },
  },
  methods: {
    openModifyColumnGroup(columnGroup) {
      this.modifyColumnGroup.title.value = columnGroup.title
      this.modifyColumnGroup.title.valid = true
      this.modifyColumnGroup.showDialog = true
    },
    updateColumnGroup() {
      if (!this.modifyColumnGroup.title.value.trim()) {
        this.modifyColumnGroup.title.valid = false
        return false
      }
      return TableService.modifyColumnGroup(this.columnGroup.tableId, this.columnGroup.id, this.modifyColumnGroup.title.value.trim()).then(() => {
        this.modifyColumnGroup.showDialog = false
        this.$emit("update")
      })
    },
    openNewColumn() {
      this.newColumn.title.value = ""
      this.newColumn.title.valid = true
      this.newColumn.type.value = types[0].value
      this.newColumn.type.valid = true
      this.newColumn.showDialog = true
    },
    saveNewColumn() {
      if (!this.newColumn.title.value.trim()) {
        this.newColumn.title.valid = false
        return false
      }
      return TableService.addColumn(this.columnGroup.tableId, this.columnGroup.id, this.newColumn.title.value.trim(), this.newColumn.type.value).then(() => {
        this.newColumn.showDialog = false
        this.$emit("update")
      })
    },
    openModifyColumn(column) {
      this.modifyColumn.id = column.id
      this.modifyColumn.title.value = column.title
      this.modifyColumn.title.valid = true
      this.modifyColumn.type.value = column.type
      this.modifyColumn.type.valid = true
      this.modifyColumn.items = column.items

      this.modifyColumn.showDialog = true
    },
    updateColumn() {
      this.$nextTick(() => {
        if (!this.modifyColumn.title.value.trim()) {
          this.modifyColumn.title.valid = false
          return false
        }
        return TableService.modifyColumn(
          this.columnGroup.tableId,
          this.columnGroup.id,
          this.modifyColumn.id,
          this.modifyColumn.title.value.trim(),
          this.modifyColumn.type.value
        ).then(() => {
          // this.modifyColumn.showDialog = false
          this.$emit("update")
        })
      })
    },
    removeColumn() {
      TableService.removeColumn(this.columnGroup.tableId, this.columnGroup.id, this.modifyColumn.id).then(() => {
        this.removeColumnConfirm = false
        this.$emit("update")
      })
    },
    removeColumnGroup() {
      $confirm(`<span class="ui red large text">${this.columnGroup.title}</span> 컬럼그룹을 삭제합니다`, "컬럼그룹 삭제", () => {
        TableService.removeColumnGroup(this.columnGroup.tableId, this.columnGroup.id).then(() => {
          this.$emit("update")
        })
      })
    },
    findType(type) {
      return lodash.find(types, { value: type })
    },
    openRemoveItem(item) {
      this.removeItemTarget.id = item.id
      this.removeItemTarget.title = item.title
      this.removeItemTarget.confirm = true
    },
    addItem() {
      TableService.addItem(this.columnGroup.tableId, this.columnGroup.id, this.modifyColumn.id, this.addItemTitle).then((item) => {
        this.$emit("update")
        this.addItemTitle = null
        this.modifyColumn.items.push(item)
      })
    },
    removeItem() {
      TableService.removeItem(this.columnGroup.tableId, this.columnGroup.id, this.modifyColumn.id, this.removeItemTarget.id)
        .then(() => {
          this.removeItemTarget.confirm = false
          this.$emit("update")
        })
        .then(() => {
          lodash.remove(this.modifyColumn.items, { id: this.removeItemTarget.id })
          this.modifyColumn.showDialog = true
        })
    },
    changeItemColor(item, color) {
      console.log(item, color)
      TableService.modifyItem(this.columnGroup.tableId, this.columnGroup.id, this.modifyColumn.id, { ...item, color: color }).then(() => {
        this.$emit("update")
      })
    },
  },
}
</script>

<style scoped lang="scss">
.group-title {
  position: relative;
  .link {
    cursor: pointer;
    padding: 5px;
    border: solid 0;
    border-radius: 0.2em;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}
.toolbar {
  position: absolute;
  right: 10px;
  top: 5px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
