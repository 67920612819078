<template>
  <div class="item" @click="onClick">
    <i class="icon" :class="icon"></i>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MSelectionItem",
  props: {
    icon: {
      type: String,
    },
  },
  methods: {
    onClick(evt) {
      this.$emit("click", evt)
    },
  },
}
</script>

<style scoped></style>
