<template>
  <div class="ui floating scrolling mini dropdown" ref="menu">
    <i class="ui ellipsis horizontal grey link icon"></i>
    <div class="menu">
      <div
        v-for="color in colors"
        :key="color.class"
        class="item"
        :class="{ active: selectedColor === color.class }"
        data-value="color.class"
        @click="selectColor(color.class)"
      >
        <div class="ui empty circular label" :class="[color.class]"></div>
        {{ color.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { MenuColors } from "@/define/Colors"

export default {
  name: "ColumnItemMenu",
  props: {
    selectedColor: String,
  },
  computed: {
    colors() {
      return MenuColors()
    },
  },
  mounted() {
    const menu = this.$refs.menu
    $(menu).dropdown()
  },
  methods: {
    selectColor(color) {
      this.$emit("color", color)
    },
  },
}
</script>

<style scoped></style>
