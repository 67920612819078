<template>
  <div>
    <column-group v-for="columnGroup in columnGroups" :key="columnGroup.id" :columnGroup="columnGroup" @update="update"></column-group>
    <m-selection-list>
      <m-selection-item icon="plus" @click="openNewColumnGroup"><span class="ui grey text">새로운 컬럼 그룹을 추가합니다</span></m-selection-item>
    </m-selection-list>
    <m-dialog v-model="newColumnGroup.showDialog" headerText="새로운 컬럼 그룹">
      <div class="ui form">
        <m-field-text label="컬럼 그룹 이름" v-model="newColumnGroup.title.value" :valid="newColumnGroup.title.valid"></m-field-text>
      </div>
      <template #actions>
        <m-btn deny @click="newColumnGroup.showDialog = false">취소</m-btn>
        <m-btn approve @click="saveNewColumnGroup"><i class="check icon"></i>확인</m-btn>
      </template>
    </m-dialog>
  </div>
</template>

<script>
import TableService from "@/service/TableService"
import MSelectionList from "@/components/common/MSelectionList"
import MSelectionItem from "@/components/common/MSelectionItem"
import MDialog from "@/components/common/MDialog"
import MFieldText from "@/components/common/MFieldText"
import MBtn from "@/components/common/MBtn"
import ColumnGroup from "./ColumnGroup"
export default {
  name: "ColumnGroups",
  components: { MBtn, MFieldText, MDialog, ColumnGroup, MSelectionList, MSelectionItem },
  props: {
    columnGroups: Array,
  },
  data() {
    return {
      newColumnGroup: {
        showDialog: false,
        title: {
          value: "",
          valid: true,
        },
      },
    }
  },
  methods: {
    openNewColumnGroup() {
      this.newColumnGroup.title.value = ""
      this.newColumnGroup.title.valid = true
      this.newColumnGroup.showDialog = true
    },
    saveNewColumnGroup() {
      if (!this.newColumnGroup.title.value.trim()) {
        this.newColumnGroup.title.valid = false
        return false
      }
      const tableId = this.$route.params.id
      return TableService.addColumnGroup(tableId, this.newColumnGroup.title.value.trim()).then(() => {
        this.newColumnGroup.showDialog = false
        this.update()
      })
    },
    update() {
      this.$emit("update")
    },
  },
}
</script>

<style scoped></style>
