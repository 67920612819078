<template>
  <div class="ui tab segment" :class="{ loading, active, bottom, attached, basic }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MTabPanel",
  props: {
    itemValue: [Number, String, Object],
    value: [Number, String, Object],
    loading: Boolean,
    bottom: Boolean,
    attached: Boolean,
    basic: Boolean,
  },
  computed: {
    active() {
      return this.itemValue === this.value
    },
  },
}
</script>

<style scoped></style>
