<template>
  <div class="ui middle aligned selection list"><slot></slot></div>
</template>

<script>
export default {
  name: "MSelectionList",
}
</script>

<style scoped></style>
