<template>
  <fragment>
    <div class="ui top attached button" v-show="!editMode" @click="onEditMode"><i class="ui edit icon"></i>편집</div>
    <div class="ui top attached buttons" v-show="editMode">
      <div class="ui button" @click="cancelEditMode"><i class="ui cancel icon"></i>취소</div>
      <div class="ui primary button" @click="saveMemo"><i class="ui check icon"></i>저장</div>
    </div>
    <div class="ui attached segment">
      <div v-show="!editMode">
        <pre>{{ table.description }}</pre>
      </div>
      <div v-show="editMode">
        <div class="ui form">
          <div class="field">
            <textarea class="transparent" id="memo-editor" placeholder="테이블 메모를 입력하세요" v-model="memo"></textarea>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import TableService from "@/service/TableService"
export default {
  name: "TableMemo",
  props: {
    table: { type: Object, required: true },
  },
  data() {
    return {
      editMode: false,
      memo: null,
    }
  },
  methods: {
    onEditMode() {
      this.memo = this.table.description
      this.editMode = true
    },
    cancelEditMode() {
      this.memo = null
      this.editMode = false
    },
    saveMemo() {
      TableService.changeTableMemo({ ...this.table, description: this.memo }).then(() => {
        this.$emit("update")
        this.memo = null
        this.editMode = false
      })
    },
  },
}
</script>

<style scoped>
textarea#memo-editor {
  /*border: 0;*/
}
</style>
